$(document).ready(function () {
	
	AOS.init();

	svg4everybody({});

	$(document).on('click', '[data-toggle="lightbox"]', function(event) {
		event.preventDefault();
		$(this).ekkoLightbox();
});

$("form").submit(function() {
  var th = $(this);
  // /*modalSubmited();
  //  console.log(th.serialize())
  $.ajax({
    type: "POST",
    url: "mail.php", //Change
    data: th.serialize()
  }).done(function() {
		$('#modal').modal('show')
    setTimeout(function() {
      // Done Functions
      th.trigger("reset");
    }, 1000);
  });
  return false;
});

$(function(){
  $(".twentytwenty-container").twentytwenty({
		before_label: 'До', // Set a custom before label
    after_label: 'После'
	});
});

var swiper = new Swiper('.main-slider', {
	pagination: {
		el: '.swiper-pagination.main-slider-pagination',
		clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + '">' + ('0' + (index + 1)) + '</span>';
		},
	},
	navigation: {
		nextEl: '.swiper-button-next.main-slider-next',
		prevEl: '.swiper-button-prev.main-slider-prev',
	},
});

var partnersSwiper = new Swiper('.partners-slider', {
	pagination: {
		el: '.swiper-pagination.partners-pagination',
		clickable: true,
	},
});

var BeforeSwiper = new Swiper('.before-after-slider', {
	simulateTouch: false,
	pagination: {
		el: '.swiper-pagination.swiper-before-after-pagination',
		clickable: true,
	},
	navigation: {
		nextEl: '.swiper-button-next.swiper-before-after-next',
		prevEl: '.swiper-button-prev.swiper-before-after-prev',
	},

});

var slideCount = function() {
	curSlide = $('.swiper-pagination-bullet-active').html()
	$('.swiper-count').html(curSlide)
}

slideCount();

swiper.on('slideChange', function () {
  slideCount()
});
$(window).scroll(function () {
	var offset = $('.section-garant').offset()
	if ($(this).scrollTop() >= offset.top - 350) {
		$('#num1').animate({
			num: 10 - 0 /* - начало */
		}, {
			duration: 1500,
			step: function (num) {
				this.innerHTML = ((num + 0).toFixed())
			}
		});
		$('#num2').animate({
			num2: 1240 - 0 /* - начало */
		}, {
			duration: 1500,
			step: function (num2) {
				this.innerHTML = ((num2 + 0).toFixed())
			}
		});
		$('#num3').animate({
			num3: 24 - 0 /* - начало */
		}, {
			duration: 1500,
			step: function (num3) {
				this.innerHTML = ((num3 + 0).toFixed())
			}
		});
		$('#num4').animate({
			num4: 8 - 0 /* - начало */
		}, {
			duration: 1500,
			step: function (num4) {
				this.innerHTML = ((num4 + 0).toFixed())
			}
		});
		$('#num5').animate({
			num4: 10 - 0 /* - начало */
		}, {
			duration: 1500,
			step: function (num4) {
				this.innerHTML = ((num4 + 0).toFixed())
			}
		});
	}
});

// // poster frame click event
// $(document).on('click','.js-videoPoster',function(ev) {
//   ev.preventDefault();
//   var $poster = $(this);
//   var $wrapper = $poster.closest('.js-videoWrapper');
//   videoPlay($wrapper);
// });

// // play the targeted video (and hide the poster frame)
// function videoPlay($wrapper) {
//   var $iframe = $wrapper.find('.js-videoIframe');
//   var src = $iframe.data('src');
//   // hide poster
//   $wrapper.addClass('videoWrapperActive');
//   // add iframe src in, starting the video
//   $iframe.attr('src',src);
// }

// // stop the targeted/all videos (and re-instate the poster frames)
// function videoStop($wrapper) {
//   // if we're stopping all videos on page
//   if (!$wrapper) {
//     var $wrapper = $('.js-videoWrapper');
//     var $iframe = $('.js-videoIframe');
//   // if we're stopping a particular video
//   } else {
//     var $iframe = $wrapper.find('.js-videoIframe');
//   }
//   // reveal poster
//   $wrapper.removeClass('videoWrapperActive');
//   // remove youtube link, stopping the video from playing in the background
//   $iframe.attr('src','');
// }

});
